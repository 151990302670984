<template>
  <v-container class="px-5">
    <p class="text-h4 titulo-card">Dashboard</p>
    <v-row class="my-4">
      <v-col>
        <v-card elevation="4" class="px-4">
          <v-row>
            <v-col cols="9" class="pb-0">
              <p class="text-h6 mb-1">School</p>
              <p class="text-h5 mb-1">{{ School }}</p>
            </v-col>
            <v-col class="my-1 pa-0 text-center" cols="3">
              <img src="../../../assets/admin/school.png" class="imagen-info" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- <v-col>
        <v-card elevation="4" class="px-4">
          <v-row>
            <v-col cols="9" class="pb-0">
              <p class="text-h6 mb-1">License end Date</p>
              <p class="text-h5 mb-1">{{DateExpiration}}</p>
            </v-col>
            <v-col class="my-1 pa-0 text-center end-date-icon" cols="3">
              <v-icon size="100" dark>
                mdi-calendar-end
              </v-icon>
            </v-col>
          </v-row>
          
        </v-card>
      </v-col> -->
    </v-row>
    <v-row class="my-5">
      <v-col>
        <v-card elevation="2" class="px-4" color="cyan darken-1" flat dark>
          <v-row>
            <v-col cols="8">
              <p class="text-h3 text-center">{{ Classroom }}</p>
              <p class="text-h5 text-center">Classrooms</p>
            </v-col>
            <v-col class="pa-2 text-center my-auto py-auto" cols="4">
              <img src="../../../assets/admin/clase2.png" class="imagen-info" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2" class="px-4" color="green darken-1" flat dark>
          <v-row>
            <v-col cols="8">
              <p class="text-h3 text-center">{{ Groups }}</p>
              <p class="text-h5 text-center">Groups</p>
            </v-col>
            <v-col class="pa-2 text-center my-auto py-auto" cols="4">
              <img src="../../../assets/admin/groups.png" class="imagen-info" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card elevation="2" class="px-4" color="amber darken-3" flat dark>
          <v-row>
            <v-col cols="8">
              <p class="text-h3 text-center">{{ Students }}</p>
              <p class="text-h5 text-center">Students</p>
            </v-col>
            <v-col class="pa-2 text-center my-auto py-auto" cols="4">
              <img
                src="../../../assets/admin/students.png"
                class="imagen-info"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-card elevation="2">
      <v-toolbar dense>
        <v-toolbar-title>Classrooms</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="cyan darken-1" dark to="/schoolTeacher/classroom">
          Go to classrooms
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-container>
</template>
<script>
import { auth, db } from "../../../plugins/firebase";
import firebase from "firebase";
import Pie from "../../../components/PieChart";
export default {
  components: {
    Pie,
  },
  data() {
    return {
      School: "",
      DateExpiration: "",
      Classroom: 0,
      Groups: 0,
      Students: 0,
      pieChartData: {},
      pieChartOptions: {
        responsive: true,
      },
      Mision1: [],
      Mision2: [],
      Mision3: [],
    };
  },
  created() {},
  methods: {
    async GetDataTeacher() {
      let iud = this.$store.state.user.uid;
      let info = await db.collection("SchoolTeacher").doc(iud).get();
      if (info.data().FirstTime) {
        //console.log(info.data().firstTime)
        this.$router.push({ path: "schoolTeacher/classroom" });
      }
      this.School = info.data().School;
      console.log(info.data());
      let TempDateExpiration = new Date(info.data().LicenseExpirationDate);
      var options = { year: "numeric", month: "long", day: "numeric" };
      let date = TempDateExpiration.toLocaleDateString("en-US", options);
      this.DateExpiration = date;
      let classrooms = info.data().Classrooms;
      if (classrooms == undefined) {
        this.Classroom = 0;
        this.Groups = 0;
        this.Students = 0;
      } else {
        let ids = Object.keys(classrooms);
        this.Classroom = ids.length;

        ids.forEach((element) => {
          this.Groups += classrooms[element].NumberGroup;
          this.Students += classrooms[element].NumberStudents;
          //newGroup.push({ id: element, name: preGroups[element].name, Students: [] })
        });
      }
    },
  },
  mounted() {
    this.GetDataTeacher();
  },
};
</script>
<style>
.school-icon {
  background-color: #1f6f1f;
}
.end-date-icon {
  background-color: #da4545;
}
.icons-info {
  font-size: 100px !important;
}
.imagen-info {
  width: 100px;
}
@media only screen and (max-width: 1200px) {
  .icons-info {
    font-size: 80px !important;
  }
  .imagen-info {
    width: 80px;
  }
}
@media only screen and (max-width: 992px) {
  .icons-info {
    font-size: 65px !important;
  }
  .imagen-info {
    width: 65px;
  }
}
@media only screen and (max-width: 768px) {
  .icons-info {
    font-size: 50px !important;
  }
  .imagen-info {
    width: 50px;
  }
}
</style>